/*###########################################################################
// Lottie
//#########################################################################*/

import lottie from "lottie-web";

const lotties = {
  config: {
    lottieItem: '.lottie-item'
  },

  elements: {},

  init() {
    const self = this

    self.elements.lottieItems = document.querySelectorAll(self.config.lottieItem)

    if (self.elements.lottieItems !== null) {
      self.handleLottieItems()
    }
  },

  handleLottieItems() {
    const self = this

    for (let i = 0; i < self.elements.lottieItems.length; i++) {
      let path = self.elements.lottieItems[i].getAttribute('data-path')
      let loop = (self.elements.lottieItems[i].getAttribute('data-loop') == 'true')

      let animation = lottie.loadAnimation({
        container: self.elements.lottieItems[i],
        renderer: 'svg',
        loop: loop,
        autoplay: true,
        path: path
      })
    }
  }
}

window.addEventListener('load', function () {
  lotties.init()
})
