/*###########################################################################
// Projects default (used on craft cms and brand pages)
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { throttle } from "../utilities/throttleDebounce"
import { inViewport } from "@js/utilities/inViewport"

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({ ignoreMobileResize: true })

const teamFit = {
  config: {
    teamFit: '.team-fit',
    sentence: '.team-fit__sentence',
    imgContainer: '.img-container',
  },

  elements: {},

  init() {
    const self = this

    self.elements.teamFit = document.querySelector(self.config.teamFit)

    if (self.elements.teamFit !== null) {
      self.elements.teamFitCards = document.querySelector(self.config.teamFitCards)
      self.elements.sentence = document.querySelector(self.config.sentence)
      self.elements.imgContainer = document.querySelector(self.config.imgContainer)

      self.handleTeamFit()
    }
  },

  handleTeamFit() {
    const self = this

		let scrollWidth = window.innerWidth > 768 ? self.elements.imgContainer.scrollWidth/1.3 + 40 : self.elements.imgContainer.scrollWidth/1.2 + 60

    gsap.to(self.elements.teamFit, {
      ease: "none",
      scrollTrigger: {
        trigger: self.elements.teamFit.parentNode,
        pin: true,
        scrub: 1,
        end: () => "+=" + (scrollWidth)
      }
    })

    gsap.set(self.elements.sentence, { y: (window.innerWidth > 768 ? "80px" : "40px"), opacity: 0 });
    gsap.to(self.elements.sentence, {
      y: "0px",
      opacity: 1,
      ease: "none",
      scrollTrigger: {
        trigger: self.elements.teamFit.parentNode,
        scrub: 1,
        start: "top +=300 middle",
        end: "+=300"
      }
    })

    gsap.set(self.elements.imgContainer, { x: window.innerWidth + 100 });
    gsap.to(self.elements.imgContainer, {
			x: -self.elements.imgContainer.scrollWidth + window.innerWidth / 2.5,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.imgContainer.parentNode,
          pin: false,
          scrub: 1,
					start: "top +=300 middle",
          end: () => "+=" + (scrollWidth)
        }
    })

    // Ensure scrolltrigger positions are correct when near element
    //
    setTimeout(function() {
      ScrollTrigger.sort()
    }, 500)

    let hasRefreshed = false
    window.addEventListener('scroll', throttle(function() {
      if (inViewport(self.elements.teamFit, 0) && hasRefreshed === false) {
        ScrollTrigger.refresh()

        hasRefreshed = true
      }
    }, 750))

		// Ensure that when accordions/expandables on the page open/close, that pinning positions are updated.
		//
		window.addEventListener('accordionOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})

		window.addEventListener('expandableOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    teamFit.init()
  }, 1000)
})
