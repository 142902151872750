/*###########################################################################
// Intro animation
//#########################################################################*/

import Cookies from 'js-cookie'
import lottie from 'lottie-web'

const introAnimation = {
  config: {
    introAnimation: '.intro-animation',
    introAnimationWrapper: '.intro-animation__wrapper'
  },

  elements: {
    introAnimation: null,
    introAnimationWrapper: null
  },

  init() {
    const self = this

    self.elements.introAnimation = document.querySelector(self.config.introAnimation)

    if (self.elements.introAnimation !== null) {
      if (Cookies.get('intro-shown') !== 'true') {
        self.elements.introAnimation.style.display = 'block'
        self.elements.introAnimationWrapper = self.elements.introAnimation.querySelector(self.config.introAnimationWrapper);

        self.handleIntroAnimation()

        setTimeout(function() {
          Cookies.set('intro-shown', true, { expires: 14 })
        }, 5000)
      } else {
        self.elements.introAnimation.parentNode.removeChild(self.elements.introAnimation)
      }
    }
  },

  handleIntroAnimation() {
    const self = this

    // Disable scroll
    document.documentElement.style.overflow = 'hidden'

    // Load lottie
    lottie.loadAnimation({
      container: self.elements.introAnimationWrapper,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: '/dist/lottie/animated-logo-light.json'
    })

    // End intro
    setTimeout(function() {
      document.documentElement.style.overflow = ''
      self.elements.introAnimation.classList.add('animate-out')
    }, 3750)

    setTimeout(function() {
      self.elements.introAnimation.parentNode.removeChild(self.elements.introAnimation)

      const event = new Event('intro-animation-done')
      window.dispatchEvent(event)
    }, 4250)
  }
}

if (Cookies.get('intro-shown') !== 'true') {
  document.querySelector('.intro-animation').style.display = 'block'
}

window.addEventListener('load', function () {
  introAnimation.init()
})