import Lenis from '@studio-freight/lenis'

window.lenis = new Lenis({
  syncTouch: true
})

window.addEventListener('load', function () {
  window.lenis.on('scroll', (e) => {
    // console.log(e)
  })

  function raf(time) {
    window.lenis.raf(time)
    requestAnimationFrame(raf)
  }

  requestAnimationFrame(raf)
})
