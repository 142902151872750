/*###########################################################################
// Projects overview
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({ ignoreMobileResize: true })

const projectsOverview = {
  config: {
    projectsOverview: '.projects-overview',
    projectsOverviewCards: '.projects-overview__cards'
  },

  elements: {},

  init() {
    const self = this

    self.elements.projectsOverview = document.querySelector(self.config.projectsOverview)

    if (self.elements.projectsOverview !== null) {
      self.elements.projectsOverviewCards = self.elements.projectsOverview.querySelector(self.config.projectsOverviewCards)

      self.handleProjectOverview()
    }
  },

  handleProjectOverview() {
    const self = this

    let scrollWidth = self.elements.projectsOverviewCards.scrollWidth

    setTimeout(function() {
      self.elements.projectsOverviewCards.style.opacity = ""
    }, 500)

    if (window.innerWidth >= 640) {
      gsap.to(self.elements.projectsOverview, {
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsOverview.parentNode,
          pin: true,
          scrub: 1,
          end: () => "+=" + (scrollWidth)
        }
      })

      gsap.set(self.elements.projectsOverviewCards, { x: window.innerWidth + 40 });
      gsap.to(self.elements.projectsOverviewCards, {
        x: -self.elements.projectsOverviewCards.scrollWidth + window.innerWidth / 2,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsOverview.parentNode,
          pin: false,
          scrub: 1,
          start: "top +=300 middle",
          end: () => "+=" + (scrollWidth),
        }
      })
    } else {
      gsap.to(self.elements.projectsOverview, {
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsOverview.parentNode,
          pin: true,
          scrub: 1,
          end: self.elements.projectsOverviewCards.scrollHeight / 2.5
        }
      })

      gsap.set(self.elements.projectsOverviewCards, { bottom: "-" + self.elements.projectsOverviewCards.scrollHeight + "px" });
      gsap.to(self.elements.projectsOverviewCards, {
        bottom: 0,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsOverview.parentNode,
          pin: false,
          scrub: 1,
          end: () => "+=" + (self.elements.projectsOverviewCards.scrollHeight / 2)
        }
      })
    }

		// Ensure that when accordions/expandables on the page open/close, that pinning positions are updated.
		//
		window.addEventListener('accordionOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})

		window.addEventListener('expandableOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    projectsOverview.init()
  }, 500)
})
