/*###########################################################################
// Projects default (used on craft cms and brand pages)
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { throttle } from "../utilities/throttleDebounce"
import { inViewport } from "@js/utilities/inViewport"

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({ ignoreMobileResize: true })

const projectsDefault = {
  config: {
    projectsDefault: '.projects-craft, .projects-brand',
    projectsDefaultCards: '.projects-craft__cards, .projects-brand__cards',
    lineTop: '.projects-craft__line-top, .projects-brand__line-top',
    lineBottom: '.projects-craft__line-bottom, .projects-brand__line-bottom',
  },

  elements: {},

  init() {
    const self = this

    self.elements.projectsDefault = document.querySelector(self.config.projectsDefault)

    if (self.elements.projectsDefault !== null) {
      self.elements.projectsDefaultCards = document.querySelector(self.config.projectsDefaultCards)
      self.elements.lineTop = document.querySelector(self.config.lineTop)
      self.elements.lineBottom = document.querySelector(self.config.lineBottom)

      self.handleProjectsDefault()
    }
  },

  handleProjectsDefault() {
    const self = this

    let scrollWidth = window.innerWidth > 768 ? self.elements.projectsDefault.scrollWidth/1.3 + 40 : self.elements.projectsDefault.scrollWidth/1.2 + 60

    if (window.innerWidth >= 640) {
      gsap.set(self.elements.projectsDefaultCards, { x: window.innerWidth + 40 });
      gsap.to(self.elements.projectsDefaultCards, {
        x: -self.elements.projectsDefaultCards.scrollWidth + window.innerWidth / 2,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsDefault.parentNode,
          pin: true,
          scrub: 1,
          end: () => "+=" + (scrollWidth)
        }
      })

      gsap.set(self.elements.lineTop, { y: (window.innerWidth > 768 ? "-80px" : "-40px"), opacity: 0 });
      gsap.to(self.elements.lineTop, {
        y: "0px",
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsDefault.parentNode,
          scrub: 1,
          start: "top +=300 middle",
          end: "+=300"
        }
      })

      gsap.set(self.elements.lineBottom, { y: (window.innerWidth > 768 ? "80px" : "40px"), opacity: 0 });
      gsap.to(self.elements.lineBottom, {
        y: "0px",
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsDefault.parentNode,
          scrub: 1,
          start: "top +=300 middle",
          end: "+=300"
        }
      })
    } else {
      gsap.set(self.elements.projectsDefaultCards, { bottom: "-" + self.elements.projectsDefaultCards.scrollHeight + "px" });
      gsap.to(self.elements.projectsDefaultCards, {
        bottom: 0,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsDefault.parentNode,
          pin: true,
          scrub: 1,
          end: () => "+=" + (self.elements.projectsDefaultCards.scrollHeight / 2)
        }
      })

      gsap.set(self.elements.lineTop, { y: "20px", opacity: 0 });
      gsap.to(self.elements.lineTop, {
        y: "0px",
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsDefault.parentNode,
          scrub: 1,
          start: "top +=120 middle",
          end: "+=100"
        }
      })

      gsap.set(self.elements.lineBottom, { y: "20px", opacity: 0 });
      gsap.to(self.elements.lineBottom, {
        y: "0px",
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsDefault.parentNode,
          scrub: 1,
          start: "top +=100 middle",
          end: "+=100"
        }
      })
    }

    // Ensure scrolltrigger positions are correct when near element
    //
    setTimeout(function() {
      ScrollTrigger.sort()
    }, 500)

    let hasRefreshed = false
    window.addEventListener('scroll', throttle(function() {
      if (inViewport(self.elements.projectsDefault, 0) && hasRefreshed === false) {
        ScrollTrigger.refresh()

        hasRefreshed = true
      }
    }, 750))

		// Ensure that when accordions/expandables on the page open/close, that pinning positions are updated.
		//
		window.addEventListener('accordionOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})

		window.addEventListener('expandableOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    projectsDefault.init()
  }, 1000)
})
