window.addEventListener('load', function() {
  const savePresetForm = document.querySelector('.savePresetForm')

  if (savePresetForm !== null) {
    const saveBtn = savePresetForm.querySelector('.save')
    const presetInput = savePresetForm.querySelector('#threeJsPreset')

    savePresetForm.addEventListener('submit', function(e) {
      e.preventDefault()

      setTimeout(function() {
        presetInput.value = saveBtn.getAttribute('data-preset')

        setTimeout(function() {
          savePresetForm.submit()
        }, 500)
      }, 500)
    })
  }
})
