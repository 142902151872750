/*###########################################################################
// Slider quotes
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay, EffectFade } from 'swiper/modules';
import { debounce } from "../utilities/throttleDebounce"

const sliderQuotes = {
  config: {
    sliderQuotes: '.sliderQuotes',
    next: '.sliderQuotes__next'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderQuotes = document.querySelectorAll(self.config.sliderQuotes)

    if (self.elements.sliderQuotes !== null) {
      for (let i = 0; i < self.elements.sliderQuotes.length; i++) {
        const slider = self.elements.sliderQuotes[i]

        self.handleSliderQuotes(slider)
      }
    }
  },

  handleSliderQuotes(slider) {
    const self = this

    let sliderInstance = null
    const next = slider.parentNode.querySelector(self.config.next)

    sliderInstance = new Swiper(slider, {
      modules: [EffectFade],
      loop: true,
      autoHeight: true,
      speed: 1000,
      centeredSlides: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: false,
      breakpoints: {
        1280: {
          autoHeight: false
        }
      }
    })

    next.addEventListener('click', function() {
      if (!sliderInstance.animating) {
        sliderInstance.slideNext()

        next.classList.add('clicked')

        setTimeout(function () {
          next.classList.remove('clicked')
        }, 1000)
      }
    })
  }
}

window.addEventListener('load', function () {
  sliderQuotes.init()
})
