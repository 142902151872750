import Remus from 'remusjs'

window.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('.remus') !== null) {
    const remus = new Remus({
      element: '.remus', // selector for main Remus container
      height: null
    })
  }
})
