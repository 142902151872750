/*###########################################################################
// Video fade
//#########################################################################*/

const videoFade = {
    config: {
        videoFade: '.video-fade'
    },
  
    elements: {},
  
    init() {
        const self = this
        
        self.elements.videoFadeEls = document.querySelectorAll(self.config.videoFade)

        if (self.elements.videoFadeEls !== null) {
            for (let i = 0; i < self.elements.videoFadeEls.length; i++) {
                const videoFadeEl = self.elements.videoFadeEls[i]

                self.handleVideoFadeEl(videoFadeEl)
            }
        }
    },
  
    handleVideoFadeEl(videoFadeEl) {
        const self = this
        
        videoFadeEl.addEventListener('canplay', function() {
            setTimeout(function() {
                videoFadeEl.style.opacity = "1"
                videoFadeEl.classList.add('loaded')
            }, 100)
        })
    }
  }
  
  window.addEventListener('load', function () {
    videoFade.init()
  })
  