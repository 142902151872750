/*###########################################################################
// Rive
//#########################################################################*/

import * as rive from "@rive-app/canvas";

const rives = {
  config: {
    riveItem: '.rive-item'
  },

  elements: {},

  riveInstances: [], // Store Rive instances for resizing

  init() {
    const self = this;

    self.elements.riveItems = document.querySelectorAll(self.config.riveItem);

    if (self.elements.riveItems !== null && self.elements.riveItems.length > 0) {
      self.handleRiveItems();

      // Listen for window resize events
      window.addEventListener("resize", () => {
        self.resizeCanvases();
      });
    }
  },

  handleRiveItems() {
    const self = this;

    for (let i = 0; i < self.elements.riveItems.length; i++) {
      const canvasElement = self.elements.riveItems[i];
      const src = canvasElement.getAttribute('data-src');

      console.log(src);

      const r = new rive.Rive({
        src: src,
        canvas: canvasElement,
        autoplay: true,
        stateMachines: ["State Machine 1"], // Replace with your state machine name
        fit: rive.Fit.Contain, // Adjust as needed
        alignment: rive.Alignment.Center,
        onLoad: () => {
          // Get the artboard's bounds for aspect ratio
          let aspectRatio = 1; // Default aspect ratio
          const bounds = r.bounds;
          if (bounds) {
            const artboardWidth = bounds.maxX - bounds.minX;
            const artboardHeight = bounds.maxY - bounds.minY;
            aspectRatio = artboardWidth / artboardHeight;
          } else {
            console.warn("Using default aspect ratio.");
          }

          // Initial resize
          self.resizeCanvas(canvasElement, r, aspectRatio);

          // Store the Rive instance, canvas element, and aspect ratio for resizing
          self.riveInstances.push({
            canvasElement: canvasElement,
            riveInstance: r,
            aspectRatio: aspectRatio,
          });

          // Get the inputs from the state machine
          const inputs = r.stateMachineInputs("State Machine 1");
          const hoverInput = inputs.find(input => input.name === "Hover");

          // Ensure the hover input exists
          if (hoverInput) {
            // Add event listeners for hover events
            canvasElement.addEventListener("mouseenter", () => {
              hoverInput.value = true;
            });

            canvasElement.addEventListener("mouseleave", () => {
              hoverInput.value = false;
            });
          } else {
            console.error("Hover input not found in the state machine.");
          }
        },
      });
    }
  },

  // Function to resize an individual canvas
  resizeCanvas(canvasElement, riveInstance, aspectRatio) {
    // Get container width (assuming the canvas parent is the container)
    const containerWidth = canvasElement.parentElement.clientWidth;
    const canvasWidth = containerWidth;
    const canvasHeight = canvasWidth / aspectRatio;

    // Set canvas CSS size
    canvasElement.style.width = `${canvasWidth}px`;
    canvasElement.style.height = `${canvasHeight}px`;

    // Adjust for device pixel ratio
    const dpr = window.devicePixelRatio || 1;
    canvasElement.width = canvasWidth * dpr;
    canvasElement.height = canvasHeight * dpr;

    // Scale the context
    const ctx = canvasElement.getContext("2d");
    ctx.resetTransform();
    ctx.scale(dpr, dpr);

    // Resize Rive drawing surface
    riveInstance.resizeDrawingSurfaceToCanvas();
  },

  // Function to resize all canvases on window resize
  resizeCanvases() {
    const self = this;

    for (let i = 0; i < self.riveInstances.length; i++) {
      const instance = self.riveInstances[i];
      self.resizeCanvas(instance.canvasElement, instance.riveInstance, instance.aspectRatio);
    }
  }
}

window.addEventListener('load', function () {
  rives.init();
});
