/*###########################################################################
// Icon scroller
//#########################################################################*/

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const iconScroller = {
  config: {
    iconScroller: '.iconScroller',
    iconScrollerTopSet: '.iconScroller__top-set',
    iconScrollerBottomSet: '.iconScroller__bottom-set'
  },

  elements: {
    iconScrollers: null
  },

  init() {
    const self = this

    self.elements.iconScrollers = document.querySelectorAll(self.config.iconScroller)

    if (self.elements.iconScrollers !== null) {
      for (let i = 0; i < self.elements.iconScrollers.length; i++) {
        const iconScroller = self.elements.iconScrollers[i]

        self.handleIconScroller(iconScroller)
      }
    }
  },

  handleIconScroller(iconScroller) {
    const self = this

    const topSet = iconScroller.querySelector(self.config.iconScrollerTopSet);
    const bottomSet = iconScroller.querySelector(self.config.iconScrollerBottomSet);

    gsap.to(topSet, {
      x: topSet.clientWidth / 1.5,
      ease: "none",
      scrollTrigger: {
        trigger: iconScroller,
        scrub: true,
        pin: false
      },
    });

    gsap.to(bottomSet, {
      x: -(bottomSet.clientWidth / 4),
      ease: "none",
      scrollTrigger: {
        trigger: iconScroller,
        scrub: true,
        pin: false
      },
    });
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    iconScroller.init()
  }, 500)
})
