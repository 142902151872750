/*###########################################################################
// Slider icon scroller
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules';
import { debounce } from '../utilities/throttleDebounce'

const sliderIconScroller = {
  config: {
    sliderIconScroller: '.sliderIconScroller'
  },

	breakpoint: 768,

  elements: {
    sliderIconScrollers: null
  },

  init() {
    const self = this

    self.elements.sliderIconScrollers = document.querySelectorAll(self.config.sliderIconScroller)

    if (self.elements.sliderIconScrollers !== null) {
      for (let i = 0; i < self.elements.sliderIconScrollers.length; i++) {
        const slider = self.elements.sliderIconScrollers[i]

        self.handleSliderIconScrollers(slider)
      }
    }
  },

  handleSliderIconScrollers(slider) {
    const self = this

		if (slider.hasAttribute('data-mobile-only') && slider.getAttribute('data-mobile-only') == 'true' && window.innerWidth < self.breakpoint) {
			self.initialiseSlider(slider, true)
		} else if (!slider.hasAttribute('data-mobile-only')) {
			self.initialiseSlider(slider, false)
		}
  },

	initialiseSlider(slider, isMobileOnly) {
		const self = this

		let sliderInstance = null

		const sliderInit = () => {
			sliderInstance = new Swiper(slider, {
				modules: [Autoplay],
				spaceBetween: 10,
				centeredSlides: true,
				speed: 2000,
				autoplay: {
					delay: 1
				},
				loop: true,
				slidesPerView: 4.25,
				allowTouchMove: false,
				disableOnInteraction: false,
				breakpoints: {
					// when window width is >= 320px
					375: {
						slidesPerView: 4.5,
						spaceBetween: 16
					},
					// when window width is >= 480px
					480: {
						slidesPerView: 4.75,
						spaceBetween: 20
					},
					// when window width is >= 640px
					640: {
						slidesPerView: 5.25,
						spaceBetween: 24
					},
					768: {
						slidesPerView: 7.5,
						spaceBetween: 32
					},
					1024: {
						slidesPerView: 8.25,
						spaceBetween: 32
					},
					1280: {
						speed: 6000,
						slidesPerView: 9.5,
						spaceBetween: 40
					},
					1440: {
						slidesPerView: 10,
						spaceBetween: 40
					},
					1600: {
						slidesPerView: 11,
						spaceBetween: 40
					}
				}
			})
		}
		sliderInit()

		if (isMobileOnly) {
			window.addEventListener('resize', debounce(function() {
				if (window.innerWidth < self.breakpoint) {
					if (sliderInstance === null) {
						sliderInit()
					}

					setTimeout(function() {
						if (sliderInstance === null) {
							sliderInit()
						}
					}, 250)
				} else {
					if (sliderInstance !== null) {
						sliderInstance.destroy(true, true)
					}

					sliderInstance = null
				}
			}, 100))
		}
	}
}

window.addEventListener('load', function () {
  sliderIconScroller.init()
})
