/*###########################################################################
// Team member
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import '../vendor/nearby'
// import { throttle } from "../utilities/throttleDebounce"
// import { inViewport } from "@js/utilities/inViewport"

gsap.registerPlugin(ScrollTrigger)

const teamMember = {
  config: {
    teamMember: '.teamMember',
    teamMemberRoles: '.teamMember__roles',
    teamMemberTitle: '.teamMember__title',
    teamMemberGradients: '.teamMember__gradients',
    teamMemberContent: '.teamMember__content'
  },

  elements: {},

  init() {
    const self = this

    self.elements.teamMembers = document.querySelectorAll(self.config.teamMember)

    if (self.elements.teamMembers !== null) {
      for (let i = 0; i < self.elements.teamMembers.length; i++) {
        const teamMember = self.elements.teamMembers[i]

        self.handleTeamMember(teamMember)
      }
    }
  },

  handleTeamMember(teamMember) {
    const self = this

    const teamMemberRoles = teamMember.querySelector(self.config.teamMemberRoles)
    gsap.set(teamMemberRoles, { opacity: 0, scale: 0.9 });

    const teamMemberTitle = teamMember.querySelector(self.config.teamMemberTitle)
    gsap.set(teamMemberTitle, { opacity: 0 });

    const teamMemberGradients = teamMember.querySelector(self.config.teamMemberGradients)
    gsap.set(teamMemberGradients, { opacity: 0 });

    const teamMemberContent = teamMember.querySelector(self.config.teamMemberContent)
    gsap.set(teamMemberContent, { opacity: 0 });

    const enter = (scrollTriggerInstance) => {
      gsap.to(teamMemberRoles, {
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "sine.inOut",
      });

      gsap.to(teamMemberTitle, {
        opacity: 1,
        duration: 1,
        ease: "sine.inOut",
      });

      gsap.to(teamMemberGradients, {
        opacity: 0.8,
        duration: 1,
        ease: "sine.inOut",
      });

      gsap.to(teamMemberContent, {
        opacity: 1,
        duration: 1,
        ease: "sine.inOut",
      });

      gsap.fromTo(teamMemberGradients, {
        rotate: 0,
        ease: "none",
      }, {
        rotate: 360,
        repeat: -1,
        duration: 10,
        ease: "none",
      });

      if (window.innerWidth >= 1024) {
        self.handleProximityEffect(teamMemberGradients)
      }

      scrollTriggerInstance.kill(false, true)
    };

    ScrollTrigger.create({
      trigger: teamMember,
      pin: false,
      start: "top +=" + window.innerHeight/2 + " middle",
      markers: false,
      onEnter: enter,
      onLeave: self => self.kill(false, true)
    });

    // Ensure scrolltrigger positions are correct when near element
    //
    setTimeout(function() {
      ScrollTrigger.sort()
    }, 500)
  },

  handleProximityEffect(gradientEl) {
    const self = this;

    let distanceThreshold = {min: 0, max: 500};
    let scaleInterval = {from: 1, to: 1.35};
    let opacityInterval = {from: 0.8, to: 1};
    let translateInterval = {from: 0, to: 10};

    const nearbyInstance = new Nearby(gradientEl, {
      onProgress: (distance) => {
        let scale = window.lineEq(scaleInterval.from, scaleInterval.to, distanceThreshold.max, distanceThreshold.min, distance);
        let opacity = window.lineEq(opacityInterval.from, opacityInterval.to, distanceThreshold.max, distanceThreshold.min, distance);
        let translate = window.lineEq(translateInterval.from, translateInterval.to, distanceThreshold.max, distanceThreshold.min, distance);

        gsap.to(gradientEl, 2, {
          ease: 'Expo.easeOut',
          scale: Math.max(scale, scaleInterval.from),
          opacity: opacity,
          x: -(Math.max(translate, translateInterval.from)),
          y: -(Math.max(translate, translateInterval.from))
        });
      }
    })
  },

  killNearbyInstance() {
    const self = this;

    self.nearbyInstance.kill();
  },
}

window.addEventListener('load', function () {
  setTimeout(function() {
    teamMember.init()
  }, 1000)
})
