/*###########################################################################
// Parallax
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const parallax = {
  config: {
    parallaxImage: '.parallaxImage',
    parallaxWebImage: '.parallaxWebImage',
    parallaxVideo: '.parallaxVideo'
  },

  elements: {},

  init() {
    const self = this

    self.elements.parallaxImages = document.querySelectorAll(self.config.parallaxImage)
    if (self.elements.parallaxImages !== null) {
      for (let i = 0; i < self.elements.parallaxImages.length; i++) {
        const parallaxImage = self.elements.parallaxImages[i]

        self.handleParallaxImage(parallaxImage)
      }
    }

    self.elements.parallaxWebImages = document.querySelectorAll(self.config.parallaxWebImage)
    if (self.elements.parallaxWebImages !== null) {
      setTimeout(function() {
        for (let i = 0; i < self.elements.parallaxWebImages.length; i++) {
          const parallaxWebImage = self.elements.parallaxWebImages[i]
          const image = parallaxWebImage.querySelector("img")

          if (image.complete) {
            self.handleParallaxWebImage(parallaxWebImage, i)
          } else {
            image.addEventListener('load', function() {
              self.handleParallaxWebImage(parallaxWebImage, i)
            })
          }
        }
      }, 500)
    }

    self.elements.parallaxVideos = document.querySelectorAll(self.config.parallaxVideo)
    if (self.elements.parallaxVideos !== null) {
      for (let i = 0; i < self.elements.parallaxVideos.length; i++) {
        const parallaxVideo = self.elements.parallaxVideos[i]

        self.handleParallaxVideo(parallaxVideo)
      }
    }

		// Ensure that when accordions/expandables on the page open/close, that pinning positions are updated.
		//
		window.addEventListener('accordionOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})

		window.addEventListener('expandableOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})
  },

  handleParallaxImage(parallaxImage) {
    const self = this

    const image = parallaxImage.querySelector("img")
    const imageMobile = parallaxImage.querySelector(".parallaxImage__mobile-img")

    let parallaxImageY = (image) => {
      if (imageMobile !== null && window.innerWidth >= 640) {
        return image.offsetHeight - parallaxImage.offsetHeight
      } else if (imageMobile === null) {
        return image.offsetHeight - parallaxImage.offsetHeight
      }
    }

    if (parallaxImage.classList.contains('parallaxImage--reverse') && window.innerWidth >= 640) {
      parallaxImageY = (image) => {
        if (imageMobile !== null && window.innerWidth >= 640) {
          return -(image.offsetHeight - parallaxImage.offsetHeight * 1.5)
        } else if (imageMobile === null) {
          return -(image.offsetHeight - parallaxImage.offsetHeight * 1.5)
        }
      }
    }

    gsap.to(image, {
      y: () => parallaxImageY(image),
      ease: "power1.out",
      scrollTrigger: {
        trigger: parallaxImage,
        scrub: true,
        pin: false
      },
    })

    if (imageMobile !== null) {
      let parallaxImageMobileY = (image) => {
        if (window.innerWidth < 640) {
          return imageMobile.offsetHeight - parallaxImage.offsetHeight
        }
      }

      gsap.to(imageMobile, {
        y: () => parallaxImageMobileY(imageMobile),
        ease: "power1.out",
        scrollTrigger: {
          trigger: parallaxImage,
          scrub: true,
          pin: false
        },
      })
    }
  },

  handleParallaxWebImage(parallaxWebImage, i) {
    const self = this

    const image = parallaxWebImage.querySelector("img")

    if (window.innerWidth >= 640) {
      let parallaxImageY = (image) => {
        return -( (image.offsetHeight - parallaxWebImage.offsetHeight) / 2 )
      }

      if (parallaxWebImage.classList.contains('parallaxWebImage--reverse')) {
        parallaxImageY = (image) => {
          return ( (image.offsetHeight - parallaxWebImage.offsetHeight) / 4)
        }
      }

      gsap.to(image, {
        y: () => parallaxImageY(image),
        ease: "power1.out",
        scrollTrigger: {
          trigger: parallaxWebImage,
          scrub: true,
          pin: false
        },
      })
    }
  },

  handleParallaxVideo(parallaxVideo) {
    const self = this

    const video = parallaxVideo.querySelector("video");

    gsap.to(video, {
      y: () => video.offsetHeight - parallaxVideo.offsetHeight,
      ease: "power1.out",
      scrollTrigger: {
        trigger: parallaxVideo,
        scrub: true,
        pin: false
      },
    });
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    parallax.init()
  }, 500)
})
