/*###########################################################################
// Responsive video
//#########################################################################*/

import { throttle, debounce } from "../utilities/throttleDebounce"
import { inViewport } from "../utilities/inViewport"

const responsiveVideo = {
  responsiveVideos: null,

  breakpoints: {
    tablet: 768,
    desktop: 1024
  },

  init: function() {
    const self = this

    // Set local elements
    self.responsiveVideos = document.querySelectorAll('.responsive-video')

    if ( self.responsiveVideos !== null ) {
      self.responsiveVideos.forEach(function(responsiveVideo, index) {
        self.handleVideoSource(responsiveVideo, false)
        self.handleVideoState(responsiveVideo)
      })

      self.handleResize()
    }
  },

  handleVideoState() {
    const self = this
  },

  handleVideoSource: function(responsiveVideo, isResize) {
    const self = this

    const video = responsiveVideo

    if (video.classList.contains('responsive-video--lazy') && isResize !== true) {
      if (inViewport(video, 0) && !video.classList.contains('responsive-video--lazyloaded')) {
        video.classList.add('responsive-video--lazyloaded')

        if (window.innerWidth <= self.breakpoints.tablet && (!video.hasAttribute('data-is-small') || video.getAttribute('data-is-small') == 'false')) {
          if (video.hasAttribute("data-video-src-sm")) {
            self.setSmallVideoSource(video)
          } else {
            self.setLargeVideoSource(video)
          }
        }
        // If above tablet
        else if (window.innerWidth > self.breakpoints.tablet && (!video.hasAttribute('data-is-large') || video.getAttribute('data-is-large') == 'false')) {
          if (video.classList.contains('responsive-video--disable-on-lg') && window.innerWidth > self.breakpoints.desktop) {
            return
          } else {
            self.setLargeVideoSource(video)
          }
        }

        if (window.innerWidth <= self.breakpoints.tablet) {
          video.setAttribute('data-is-small', true)
          video.setAttribute('data-is-large', false)
        } else {
          video.setAttribute('data-is-small', false)
          video.setAttribute('data-is-large', true)
        }
      }

      if (inViewport(video, 0) && video.hasAttribute('autoplay')) {
        video.play()
        video.setAttribute('data-playing', true)
      } else {
        video.pause()
        video.setAttribute('data-playing', false)
      }

      window.addEventListener('scroll', throttle(function() {
        if (inViewport(video, 0) && !video.classList.contains('responsive-video--lazyloaded')) {
          video.classList.add('responsive-video--lazyloaded')

          if (window.innerWidth <= self.breakpoints.tablet && (!video.hasAttribute('data-is-small') || video.getAttribute('data-is-small') == 'false')) {
            if (video.hasAttribute("data-video-src-sm")) {
              self.setSmallVideoSource(video)
            } else {
              self.setLargeVideoSource(video)
            }
          }
          // If above tablet
          else if (window.innerWidth > self.breakpoints.tablet && (!video.hasAttribute('data-is-large') || video.getAttribute('data-is-large') == 'false')) {
            if (video.classList.contains('responsive-video--disable-on-lg') && window.innerWidth > self.breakpoints.desktop) {
              return
            } else {
              self.setLargeVideoSource(video)
            }
          }

          if (window.innerWidth <= self.breakpoints.tablet) {
            video.setAttribute('data-is-small', true)
            video.setAttribute('data-is-large', false)
          } else {
            video.setAttribute('data-is-small', false)
            video.setAttribute('data-is-large', true)
          }
        }

        if (inViewport(video, 0) && video.hasAttribute('autoplay')) {
          video.play()
          video.setAttribute('data-playing', true)
        } else {
          video.pause()
          video.setAttribute('data-playing', false)
        }
      }, 200))
    } else {
      if (window.innerWidth <= self.breakpoints.tablet && (!video.hasAttribute('data-is-small') || video.getAttribute('data-is-small') == 'false')) {
        if (video.hasAttribute("data-video-src-sm")) {
          self.setSmallVideoSource(video)
        } else {
          self.setLargeVideoSource(video)
        }
      }
      // If above tablet
      else if (window.innerWidth > self.breakpoints.tablet && (!video.hasAttribute('data-is-large') || video.getAttribute('data-is-large') == 'false')) {
        if (video.classList.contains('responsive-video--disable-on-lg') && window.innerWidth > self.breakpoints.desktop) {
          return
        } else {
          self.setLargeVideoSource(video)
        }
      }

      if (window.innerWidth <= self.breakpoints.tablet) {
        video.setAttribute('data-is-small', true)
        video.setAttribute('data-is-large', false)
      } else {
        video.setAttribute('data-is-small', false)
        video.setAttribute('data-is-large', true)
      }

      if (inViewport(video, 0) && video.hasAttribute('autoplay')) {
        video.play()
        video.setAttribute('data-playing', true)
      } else {
        video.pause()
        video.setAttribute('data-playing', false)
      }

      window.addEventListener('scroll', throttle(function() {
        if (inViewport(video, 0) && video.hasAttribute('autoplay')) {
          video.play()
          video.setAttribute('data-playing', true)
        } else {
          video.pause()
          video.setAttribute('data-playing', false)
        }
      }, 200))
    }
  },

  setLargeVideoSource: function(video) {
    const self = this

    // Create sources
    //
    let sources = "<source src='" + video.getAttribute("data-video-src-lg") + "'>"

    // Reload the video with new sources
    //
    video.innerHTML = sources
    video.load()

    // Set screen size state of this video
    //
    video.setAttribute('data-is-small', false)
    video.setAttribute('data-is-large', true)
  },

  setSmallVideoSource: function(video) {
    const self = this

    // Create source
    //
    let sources = "<source src='" + video.getAttribute("data-video-src-sm") + "'>"

    // Reload the video with new sources
    //
    video.innerHTML = sources
    video.load()

    // Set screen size state of this video
    //
    video.setAttribute('data-is-small', true)
    video.setAttribute('data-is-large', false)
  },

  handleResize: function() {
    const self = this

    self.responsiveVideos.forEach(function(responsiveVideo, index) {

      let width = window.innerWidth

      window.addEventListener("resize", debounce(function() {
        if ( window.innerWidth !== width ) {
          width = window.innerWidth

          self.handleVideoSource(responsiveVideo, true)
        }
      }, 500))

    });
  }
};

window.addEventListener('load', function () {
  responsiveVideo.init()
})
