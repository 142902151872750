/*###########################################################################
// Scroll to Section
//#########################################################################*/

import { scrollTo } from "../utilities/scrollTo"


const scrollToSection = {
  config: {
    button: '.scroll-to'
  },

  elements: {},

  init() {
    const self = this

    self.scrollToSection()
  },

	scrollToSection() {
		const self = this
		const elements = document.querySelectorAll('[data-scroll-to]')

		// Loop through each element and add the event listener
		elements.forEach(element => {
				element.addEventListener('click', function(e) {
					e.preventDefault()
						// Get the target section's ID from the data attribute
						const self = this
						const targetId = self.getAttribute('data-scroll-to');


						// Use querySelector to get a single element
						const targetSection = document.getElementById(`${targetId}`)
						const headerHeight = document.querySelector('.header').clientHeight

						scrollTo(targetSection, -headerHeight)
				});
		});

	},

}

window.addEventListener('load', function () {
  scrollToSection.init()
})
