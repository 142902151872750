/*###########################################################################
// Logo stacker
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({ ignoreMobileResize: true })

const logoStacker = {
  config: {
    logoStacker: '.logoStacker',
    lineTop: '.logoStacker__line-top',
    lineBottom: '.logoStacker__line-bottom',
    logoStackerItem1: '.logoStacker__item--1',
    logoStackerItem2: '.logoStacker__item--2',
    logoStackerItem3: '.logoStacker__item--3',
    logoStackerItem4: '.logoStacker__item--4'
  },

  elements: {},

  init() {
    const self = this

    self.elements.logoStacker = document.querySelector(self.config.logoStacker)

    if (self.elements.logoStacker !== null) {
      self.elements.lineTop = self.elements.logoStacker.querySelector(self.config.lineTop)
      self.elements.lineBottom = self.elements.logoStacker.querySelector(self.config.lineBottom)
      self.elements.logoStackerItem1 = self.elements.logoStacker.querySelector(self.config.logoStackerItem1)
      self.elements.logoStackerItem2 = self.elements.logoStacker.querySelector(self.config.logoStackerItem2)
      self.elements.logoStackerItem3 = self.elements.logoStacker.querySelector(self.config.logoStackerItem3)
      self.elements.logoStackerItem4 = self.elements.logoStacker.querySelector(self.config.logoStackerItem4)

      self.handleLogoStacker()
    }
  },

  handleLogoStacker() {
    const self = this

    gsap.set(self.elements.lineTop, { y: (window.innerWidth > 768 ? "-80px" : "-40px"), opacity: 0 });
    gsap.to(self.elements.lineTop, {
      y: "0px",
      opacity: 1,
      ease: "none",
      scrollTrigger: {
        trigger: self.elements.logoStacker,
        scrub: 1,
        start: "top +=300 middle",
        end: "+=300"
      }
    })

    gsap.set(self.elements.lineBottom, { y: (window.innerWidth > 768 ? "80px" : "40px"), opacity: 0 });
    gsap.to(self.elements.lineBottom, {
      y: "0px",
      opacity: 1,
      ease: "none",
      scrollTrigger: {
        trigger: self.elements.logoStacker,
        scrub: 1,
        start: "top +=300 middle",
        end: "+=300"
      }
    })

    gsap.set(self.elements.logoStackerItem1, { y: window.innerHeight * 1.25 });
    gsap.set(self.elements.logoStackerItem2, { y: window.innerHeight * 1.25 });
    gsap.set(self.elements.logoStackerItem3, { y: window.innerHeight * 1.25 });
    gsap.set(self.elements.logoStackerItem4, { y: window.innerHeight * 1.25, rotate: 10 });

    const stackingTl = gsap.timeline({
      scrollTrigger: {
        trigger: self.elements.logoStacker.parentNode,
        start: "top middle",
        scrub: 1,
        pin: true,
      }
    })

    stackingTl.to(self.elements.logoStackerItem1, { y: 0, rotate:-30, duration: 0.8 })
    stackingTl.to(self.elements.logoStackerItem2, { y: 0, rotate:-20, duration: 0.8 })
    stackingTl.to(self.elements.logoStackerItem3, { y: 0, rotate:-10, duration: 0.8 })
    stackingTl.to(self.elements.logoStackerItem4, { y: 0, rotate: 0, duration: 0.8 })

		// Ensure that when accordions/expandables on the page open/close, that pinning positions are updated.
		//
		window.addEventListener('accordionOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})

		window.addEventListener('expandableOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    logoStacker.init()
  }, 1000)
})
