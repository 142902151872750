import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function handleLazyLoad(config={}) {
  let lazyImages = gsap.utils.toArray("img[loading='lazy']"),
    timeout = gsap.delayedCall(config.timeout || 1, ScrollTrigger.refresh).pause(),
    lazyMode = config.lazy !== false,
    imgLoaded = lazyImages.length,
    onImgLoad = () => lazyMode ? timeout.restart(true) : --imgLoaded || ScrollTrigger.refresh();
  lazyImages.forEach((img, i) => {
    lazyMode || (img.loading = "eager");

    if (!img.classList.contains('prevent-scrolltrigger-refresh')) {
      img.naturalWidth ? onImgLoad() : img.addEventListener("load", onImgLoad);
    }
  });
}

// usage: you can optionally set lazy to false to change all images to load="eager". timeout is how many seconds it throttles the loading events that call ScrollTrigger.refresh()

window.addEventListener('DOMContentLoaded', function () {
  handleLazyLoad({ timeout: 1 });
})
