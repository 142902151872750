/*###########################################################################
// Projects home
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({ ignoreMobileResize: true })

const projectsHome = {
  config: {
    projectsHome: '.projects-home',
    projectsHomeCards: '.projects-home__cards',
    wordLeft: '.projects-home__word-left',
    wordRight: '.projects-home__word-right'
  },

  elements: {},

  init() {
    const self = this

    self.elements.projectsHome = document.querySelector(self.config.projectsHome)

    if (self.elements.projectsHome !== null) {
      self.elements.projectsHomeCards = document.querySelector(self.config.projectsHomeCards)
      self.elements.wordLeft = document.querySelector(self.config.wordLeft)
      self.elements.wordRight = document.querySelector(self.config.wordRight)

      self.handleProjectsHome()
    }
  },

  handleProjectsHome() {
    const self = this

    let scrollWidth = window.innerWidth > 768 ? self.elements.projectsHomeCards.scrollWidth/1.3 + 40 : self.elements.projectsHomeCards.scrollWidth/1.2 + 60

    if (window.innerWidth >= 640) {
      gsap.set(self.elements.projectsHomeCards, { x: window.innerWidth + 40 });
      gsap.to(self.elements.projectsHomeCards, {
        x: -self.elements.projectsHomeCards.scrollWidth + window.innerWidth / 2,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsHome.parentNode,
          pin: true,
          scrub: 1,
          end: () => "+=" + (scrollWidth)
        }
      })

      gsap.set(self.elements.wordLeft, { x: (window.innerWidth > 768 ? "-400px" : "-200px"), opacity: 0 });
      gsap.to(self.elements.wordLeft, {
        x: "0px",
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsHome.parentNode,
          scrub: 1,
          start: "top +=300 middle",
          end: "+=300"
        }
      })

      gsap.set(self.elements.wordRight, { x: (window.innerWidth > 768 ? "400px" : "200px"), opacity: 0 });
      gsap.to(self.elements.wordRight, {
        x: "0px",
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsHome.parentNode,
          scrub: 1,
          start: "top +=300 middle",
          end: "+=300"
        }
      })
    } else {
      gsap.set(self.elements.projectsHomeCards, { bottom: "-" + self.elements.projectsHomeCards.scrollHeight + "px" });
      gsap.to(self.elements.projectsHomeCards, {
        bottom: 0,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsHome.parentNode,
          pin: true,
          scrub: 1,
          end: () => "+=" + (self.elements.projectsHomeCards.scrollHeight / 2)
        }
      })

      gsap.set(self.elements.wordLeft, { y: "30px", opacity: 0 });
      gsap.to(self.elements.wordLeft, {
        y: "0px",
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsHome.parentNode,
          scrub: 1,
          start: "top +=130 middle",
          end: "+=200"
        }
      })

      gsap.set(self.elements.wordRight, { y: "30px", opacity: 0 });
      gsap.to(self.elements.wordRight, {
        y: "0px",
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: self.elements.projectsHome.parentNode,
          scrub: 1,
          start: "top +=100 middle",
          end: "+=200"
        }
      })
    }

		// Ensure that when accordions/expandables on the page open/close, that pinning positions are updated.
		//
		window.addEventListener('accordionOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})

		window.addEventListener('expandableOpen', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    projectsHome.init()
  }, 1000)
})
