/*###########################################################################
// Expandable
//#########################################################################*/

import { throttle } from "../utilities/throttleDebounce"
import { scrollTo } from "../utilities/scrollTo"

const expandable = {
	config: {
		expandable: '.expandable',
		expandableInner: '.expandable__inner',
		expandableTrigger: '.expandable__trigger'
	},

	state: {
		isOpen: false
	},

	elements: {
		expandables: null
	},

	init() {
		const self = this

		self.elements.expandables = document.querySelectorAll(self.config.expandable)

		if (self.elements.expandables !== null) {

			for (let i = 0; i < self.elements.expandables.length; i++) {
				const expandable = self.elements.expandables[i]
				self.handleExpandable(expandable)
			}
		}
	},

	handleExpandable(expandable) {
		const self = this

		const expandableInner = expandable.querySelector(self.config.expandableInner)
		const expandableTrigger = expandable.querySelector(self.config.expandableTrigger)

		expandableTrigger.addEventListener('click', function (e) {
			e.preventDefault()

			if (!expandable.classList.contains('open')) {
				self.setInnerHeight(expandable)
				expandable.classList.add('open')

				if (expandableTrigger.getAttribute('data-opened-text') && expandableTrigger.getAttribute('data-opened-text') !== '') {
					expandableTrigger.innerText = expandableTrigger.getAttribute('data-opened-text')
				} else {
					expandableTrigger.innerText = 'Less'
				}
			} else {
				self.unsetInnerHeight(expandableInner)
				expandable.classList.remove('open')

				if (expandableTrigger.getAttribute('data-closed-text') && expandableTrigger.getAttribute('data-closed-text') !== '') {
					expandableTrigger.innerText = expandableTrigger.getAttribute('data-closed-text')
				} else {
					expandableTrigger.innerText = 'More'
				}

				setTimeout(function () {
					self.scrollToExpandable(expandable)
				}, 100)
			}

			// Trigger an event so other components can listen for height changes
			//
			let expandableEvent = new Event('expandableOpen')
			window.dispatchEvent(expandableEvent)
		})

		expandable.setAttribute('data-inner-height', expandableInner.scrollHeight)
		window.addEventListener('resize', throttle(function () {
			expandable.setAttribute('data-inner-height', expandableInner.scrollHeight)
		}, 250))
	},

	setInnerHeight(expandable) {
		const self = this

		const expandableInner = expandable.querySelector(self.config.expandableInner)

		expandableInner.style.height = expandable.getAttribute('data-inner-height') + 'px'
	},

	unsetInnerHeight(expandableInner) {
		const self = this

		expandableInner.style.height = ''
	},

	scrollToExpandable(expandable) {
		const offsetAmount = document.querySelector('.header').clientHeight + 40
		scrollTo(expandable, -offsetAmount)
	}
}

window.addEventListener('load', function () {
	setTimeout(function () {
		expandable.init()
	}, 500)
})
