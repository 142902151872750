/*###########################################################################
// Height screen (h-screen = height: 100vh) mobile handling
//#########################################################################*/

import { throttle } from "../utilities/throttleDebounce"

const hScreenCalc = {
  config: {
    hScreenCalc: '.h-screen'
  },

  elements: {},

  init() {
    const self = this

    self.elements.hScreenEls = document.querySelectorAll(self.config.hScreenCalc)

    if (self.elements.hScreenEls !== null) {
      for (let i = 0; i < self.elements.hScreenEls.length; i++) {
        const hScreenEl = self.elements.hScreenEls[i]

        self.handleHScreen(hScreenEl)
      }
    }
  },

  handleHScreen(hScreenEl) {
    const self = this

    hScreenEl.style.height = ''
    hScreenEl.style.height = window.innerHeight + "px"

    window.addEventListener("resize", throttle(function() {
      hScreenEl.style.height = ''
      hScreenEl.style.height = window.innerHeight + "px"

      setTimeout(function() {
        hScreenEl.style.height = ''
        hScreenEl.style.height = window.innerHeight + "px"
      }, 500)
    }, 500))
  }
}

window.addEventListener('load', function () {
  hScreenCalc.init()
})
