const rangeRadioSwapper = {
  config: {
    inputs: '*[data-radio-slider=true] input[type="radio"]',
    radioSliders: '*[data-radio-slider=true]',
  },

  elements: {
    inputs: null,
    radioGroups: {},
  },

  init() {
    const self = this;

    // Collect all radio inputs.
    self.elements.inputs = document.querySelectorAll(self.config.inputs);

    // Group radios by their 'name' attribute.
    self.elements.inputs.forEach(input => {
      const groupName = input.getAttribute('name');
      if (!self.elements.radioGroups[groupName]) {
        self.elements.radioGroups[groupName] = [];
      }
      self.elements.radioGroups[groupName].push(input);
    });

  },

}

window.addEventListener('load', function () {
  rangeRadioSwapper.init();
});
