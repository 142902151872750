/*###########################################################################
// Safari fixes
//#########################################################################*/

const safariFixes = {
  init() {
    const self = this

    const contrastEls = document.querySelectorAll(".contrast-\\[0\\.98\\], .contrast-\\[1\\.05\\]")

    for (let i = 0; i < contrastEls.length; i++) {
      const contrastEl = contrastEls[i]

      self.handleContrastSafari(contrastEl)
    }
  },

  handleContrastSafari(contrastEl) {
    const self = this

    contrastEl.classList.remove('contrast-[0.98]')
    contrastEl.classList.remove('contrast-[1.05]')
  }
}

window.addEventListener('load', function () {
  if (document.body.classList.contains('safari')) {
    safariFixes.init()
  }
})
