/*###########################################################################
// Approach
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const approachBgSwitching = {
  config: {
    approachScenes: '.approachScenes',
    discovery: '.discovery'
  },

  elements: {
    approachScenes: null,
    approachScenesBgContainer: null,
    discovery: null,
  },

  init() {
    const self = this

    self.elements.approachScenes = document.querySelector(self.config.approachScenes)

    if (self.elements.approachScenes !== null) {
      self.elements.approachScenesBgContainer = self.elements.approachScenes.parentNode
      self.elements.discovery = document.querySelector(self.config.discovery)

      if (window.innerWidth >= 1024) {
        self.handleApproachScenesBg()
      }
    }
  },

  handleApproachScenesBg() {
    const self = this

    gsap.to(self.elements.approachScenesBgContainer, {
      backgroundColor: '#ffffff',
      ease: "power1.out",
      scrollTrigger: {
        trigger: self.elements.discovery,
        scrub: true,
        pin: false
      },
    })
  }
}

window.addEventListener('load', function () {
  approachBgSwitching.init()
})
