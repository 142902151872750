/*###########################################################################
// Scroll to Top Button
//#########################################################################*/

import { throttle } from '../utilities/throttleDebounce'
import { inViewport } from '../utilities/inViewport'

const scrollToTop = {
  config: {
    button: '.scroll-to-top'
  },

	state: {
		pageHeight: 0,
		triggerPoint: 0
	},

  elements: {},

  init() {
    const self = this

    self.handleScrollToTop()
  },

  handleScrollToTop() {
    const self = this

    self.elements.button = document.querySelector('.scroll-to-top')
    self.elements.footerBottom = document.querySelector('.footer__bottom')

		const body = document.body
    const doc = document.documentElement

		self.state.pageHeight = Math.max(body.scrollHeight, body.offsetHeight, doc.clientHeight, doc.scrollHeight, doc.offsetHeight)
		self.state.triggerPoint = window.innerHeight * 2

    window.lenis.on("scroll", throttle(function() {
    	let distanceToTop = window.lenis.actualScroll

			if (window.innerWidth >= 768 && self.state.pageHeight > 2000) {
				self.updateScrollState(distanceToTop)
			} else if (window.innerWidth < 768 && self.state.pageHeight > 2250) {
				self.updateScrollState(distanceToTop)
			}
    }, 250))

    self.elements.button.addEventListener('click', function(){
      window.lenis.scrollTo(0, {
        duration: 1.25,
        easing: (t) => -(Math.cos(Math.PI * t) - 1) / 2
      })
    })

		window.addEventListener('resize', throttle(function () {
			self.state.pageHeight = Math.max(body.scrollHeight, body.offsetHeight, doc.clientHeight, doc.scrollHeight, doc.offsetHeight)
		}, 250))
  },

	updateScrollState(distanceToTop) {
		const self = this

		if (distanceToTop >= self.state.triggerPoint) {
			self.elements.button.classList.add("is-active")
		} else {
			self.elements.button.classList.remove("is-active")
		}

		if (inViewport(self.elements.footerBottom, 0)) {
			self.elements.button.classList.add("is-over-footer")
		} else {
			self.elements.button.classList.remove("is-over-footer")
		}
	}
}

window.addEventListener('load', function () {
  setTimeout(function() {
		scrollToTop.init()
	}, 1500)
})
