/*###########################################################################
// Slider icon text blocks
//#########################################################################*/

import Swiper from 'swiper'
import { debounce } from "../utilities/throttleDebounce"

const sliderIconTextBlocks = {
  config: {
    sliderIconTextBlocks: '.sliderIconTextBlocks',
    slide: '.sliderIconTextBlocks__slide',
    progressBar: '.sliderIconTextBlocks__progress__bar'
  },

  elements: {
    sliderIconTextBlocks: null
  },

  init() {
    const self = this

    self.elements.sliderIconTextBlocks = document.querySelectorAll(self.config.sliderIconTextBlocks)

    if (self.elements.sliderIconTextBlocks !== null && window.innerWidth < 768) {
      for (let i = 0; i < self.elements.sliderIconTextBlocks.length; i++) {
        const slider = self.elements.sliderIconTextBlocks[i]

        self.handleSliderIconTextBlocks(slider)
      }
    }
  },

  handleSliderIconTextBlocks(slider) {
    const self = this

    const slides = document.querySelectorAll(self.config.slide)

    let sliderInstance = null

    if (window.innerWidth < 768) {
      sliderInstance = new Swiper(slider, {
        spaceBetween: 40,
        loop: false,
        slidesPerView: 1.25
      })

      const slideWidthPercentage = 100 / (sliderInstance.slides.length - 1)
      sliderInstance.on('slideChange', function () {
        self.updateProgressWidth(slider, sliderInstance, slideWidthPercentage)
      });
    } else {
      sliderInstance = null
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i]

        slide.classList.remove('swiper-slide')
      }
    }

    window.addEventListener("resize", debounce(function() {
      if (window.innerWidth < 768 && sliderInstance == null) {
        for (let i = 0; i < slides.length; i++) {
          const slide = slides[i]

          slide.classList.add('swiper-slide')
        }

        setTimeout(function() {
          sliderInstance = new Swiper(slider, {
            spaceBetween: 40,
            loop: false,
            slidesPerView: 1.25
          })

          const slideWidthPercentage = 100 / (sliderInstance.slides.length - 1)
          sliderInstance.on('slideChange', function () {
            self.updateProgressWidth(slider, sliderInstance, slideWidthPercentage)
          });
        }, 250)
      } else if (window.innerWidth >= 768) {
        if (sliderInstance !== null) {
          for (let i = 0; i < slides.length; i++) {
            const slide = slides[i]

            slide.classList.remove('swiper-slide')
          }

          sliderInstance.destroy(true, true)
          sliderInstance = null

          const progressBar = slider.querySelector(self.config.progressBar)
          progressBar.style.width = ""
        }
      }
    }, 500))
  },

  updateProgressWidth(slider, sliderInstance, slideWidthPercentage) {
    const self = this

    const progressBar = slider.querySelector(self.config.progressBar)
    const progressBarArrow = progressBar.parentNode.querySelector('svg')

    progressBar.style.width = slideWidthPercentage * (sliderInstance.activeIndex) + "%"

    if (sliderInstance.isEnd) {
      const computedStyle = window.getComputedStyle(progressBar)

      setTimeout(function() {
        progressBarArrow.style.color = computedStyle.backgroundColor
      }, 300)
    } else {
      progressBarArrow.style.color = ''
    }
  }
}

window.addEventListener('load', function () {
  sliderIconTextBlocks.init()
})
