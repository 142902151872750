/*###########################################################################
// Hero
//#########################################################################*/

import { gsap } from "gsap";
import "../vendor/SplitText";
gsap.registerPlugin(SplitText);

const hero = {
  config: {
    hero: '.hero',
    heroHeading: '.hero__heading',
    heroText: '.hero__text',
    heroButton: '.hero__button'
  },

  elements: {
    hero: null,
    heroHeading: null,
    heroText: null,
    heroButton: null
  },

  init() {
    const self = this

    self.elements.hero = document.querySelector(self.config.hero)

    if (self.elements.hero !== null) {
      self.elements.heroHeading = self.elements.hero.querySelector(self.config.heroHeading)
      self.elements.heroText = self.elements.hero.querySelector(self.config.heroText)
      self.elements.heroButton = self.elements.hero.querySelector(self.config.heroButton)

      if (self.elements.heroHeading !== null) {
        self.handleHeroHeadingAnimation()
      }

      if (self.elements.heroText !== null) {
        self.handleHeroTextAnimation()
      }

      if (self.elements.heroButton !== null) {
        self.handleHeroButtonAnimation()
      }
    }
  },

  handleHeroHeadingAnimation() {
    const self = this

    // Animate text
    const split = new SplitText(
      self.elements.heroHeading,
      { type: "words", linesClass: "lineChild" }
    );

    // Outer wrap each line with a div
    split.words.forEach((line) => {
      const div = document.createElement("span");
      div.classList.add("overflow-hidden", "py-1", "-mb-1");
      line.parentNode.insertBefore(div, line);
      div.appendChild(line);
    });

    const words = split.words;

    gsap.set(words, { y: 32, autoAlpha: 0 });

    setTimeout(function() {
      self.elements.heroHeading.classList.remove('opacity-0');

      gsap.to(words, {
        y: 0,
        autoAlpha: 1,
        duration: 0.75,
        ease: "power2.out",
        stagger: .1325,
      });
    }, 250)
  },

  handleHeroTextAnimation() {
    const self = this

    self.elements.heroText.classList.add('transition-opacity');
    self.elements.heroText.classList.add('duration-500');

    setTimeout(function() {
      self.elements.heroText.classList.remove('opacity-0');
    }, 650)
  },

  handleHeroButtonAnimation() {
    const self = this

    self.elements.heroButton.classList.add('transition-opacity');
    self.elements.heroButton.classList.add('duration-500');

    setTimeout(function() {
      self.elements.heroButton.classList.remove('opacity-0');
    }, 850)
  }
}

window.addEventListener('load', function () {
  hero.init()
})
